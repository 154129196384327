@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fafafa;
  color: black;
  scroll-behavior: smooth;
  width: 100vw;
  overflow-x: hidden;
}

html::-webkit-scrollbar {
  width: 0.2rem;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar-track {
  background: rgb(46, 46, 46);
  border-radius: 1rem;
}

html::-webkit-scrollbar-thumb {
  background: #fe6903;
  border-radius: 1rem;
}
.scroll::-webkit-scrollbar {
  width: 0.2rem;
  scroll-behavior: smooth;
}

.scroll::-webkit-scrollbar-track {
  background: rgb(46, 46, 46);
  border-radius: 1rem;
}

.scroll::-webkit-scrollbar-thumb {
  background: #fe6903;
  border-radius: 1rem;
}


